import React, { useCallback } from 'react';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { Button } from '@/components/ui/button';
import { telegramShare, getShareLink, SHARE_TEXT } from '@/utils/shareLinks';
import { userInfoAtom } from '@/store/userInfo';
import { initUtils } from '@telegram-apps/sdk-react';
import { useStore } from 'jotai';
import { cn } from '@/lib/utils';
import { useTrack } from '@/hooks/useTrack';

type ShareButtonProps = {
  text: string;
  onShareSuccess: () => void;
  className?: string;
  shadowColor?: string;
  trackData?: {
    scene: string;
    entrance: string;
  };
};

export const ShareButton: React.FC<ShareButtonProps> = ({
  text,
  onShareSuccess,
  className,
  shadowColor,
  trackData,
}) => {
  const { track } = useTrack();
  const utils = initUtils();
  const store = useStore();
  const userInfo = store.get(userInfoAtom);

  const handleShare = useCallback(() => {
    utils.openTelegramLink(
      telegramShare({
        link: getShareLink('pina', userInfo.tgId),
        shareText: SHARE_TEXT,
      }),
    );
    track('share_trigger', {
      shareScene: trackData?.scene,
      entrance: trackData?.entrance,
    });
    onShareSuccess && onShareSuccess();
  }, [onShareSuccess]);

  return (
    <Button
      onClick={handleShare}
      className={cn(
        'flex justify-center items-center gap-2 px-[20px] h-[60px] flex-shrink-0 bg-[#F6C334]',
        className,
      )}
      shadowColor={shadowColor || '#F6C334'}
    >
      <Image
        width={30}
        height={30}
        src={getPublicAsset('/images/game/share.png')}
        alt=""
      />
      <span className="text-white stroke-1 stroke-black">{text}</span>
    </Button>
  );
};
