import Image from 'next/image';

interface RewardItemProps {
    icon: string;
    label: string;
    value?: string | number;
}

export default function RewardItem({ icon, label, value }: RewardItemProps) {
    return (
        <div className='bg-[#E5DFFF] border-[1px] border-[#AEA2E1] rounded-[10px] p-2 flex justify-between items-center'>
            <div className='flex items-center gap-2 flex-1'>
                <Image className='w-6 h-6' src={icon} alt='reward-icon' width={20} height={20} />
                <div className='font-comicbd text-sm'>{label}</div>
            </div>
            <div className=' text-[#FFDF75] stroke-black text-lg font-bold tracking-wide'>{value ? `${value} X` : ''}</div>
        </div>
    );
}