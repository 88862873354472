'use client';

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import Image from 'next/image';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/nextjs';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogAction,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { PotionItem, PotionPina, PotionDetail,
  PotionDetailResponse, fetchPotionDetail } from '@/apis/potion';
import { useBagList } from '@/apis/bag';
import { initInvoice } from '@telegram-apps/sdk';
import { fetchGetGameItem, EventId, useGameItemPackages } from '@/apis/game';
import { getPaymentLink } from '@/apis/payment';
import { ShowPromiseResult } from '@/types/adsgram';
import { useToast } from '@/components/ui/use-toast';
import { store } from '@/store';
import { tokenManager } from '@/store/userInfo';
import { useAtom } from 'jotai';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { cn } from '@/lib/utils';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import useUserStatus from '@/hooks/useUserStatus';
import { isAdPlayingAtom } from '@/store/runtime';
import formatNumber from '@/utils/number/formatter';
import { useGameWebSocket, WSHandlers } from '@/hooks/useGameWebSocket';
import { formatMoney } from '@/utils/decimal/formatMoney';
import { LoadingIcon } from '@/components/ui/loading';
import { isProduction } from '@/utils/checkRuntime';
import { track } from '@/utils/thinkdata/track';

type DialogProps = {
  onClose?: Function;
};

type PayOrder = {
  packageId: string,
  paymentId: string,
  tgId: string,
  usdt: string,
  category: string,
};

type GameItem = {
  gameItemType: string,
  gameItemId: string,
  haveCount: string;
  gameItemSubId: string;
  gameItemName: string;
};

type PackageInfo = {
  id: string,
  name: string,
  description: string,
  star: string,
  usdt: string,
  gameItems: GameItem[],
};

type BuyPackagesResponse = {
  payOrder: PayOrder,
  packageInfo: PackageInfo,
};

export const Packages: React.FC<DialogProps> = ({
  onClose
}) => {

  if (isProduction) return null;

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<GameItem[]>([]);
  const [token, ] = useAtom(tokenManager, { store: store });
  const wsHandlers: WSHandlers = {
    9: (data:BuyPackagesResponse) => {
      setItems(data.packageInfo.gameItems);
    }
  };

  useGameWebSocket(token, wsHandlers);

  const closeHandler = () => {
    setOpen(false);
    !!onClose && onClose();
  };

  return (
    <AlertDialog open={open}>
      <AlertDialogContent
        className="relative flex flex-col w-10/12 p-0 bg-[#FFF4DA] font-comicbd justify-center items-center"
        shadowColor="#EE9B40"
        enable3DBorder
      >
        <BaseInteractive onInteractiveClick={closeHandler}>
          <div className="closeButton absolute w-[32px] h-[32px] right-[-16px] top-[-16px] active:translate-x-0.5 active:translate-y-0.5 z-40">
            <Image fill sizes="80vw" src={getPublicAsset('/images/lobby/close.png')} alt="close" />
          </div>
        </BaseInteractive>
        <AlertDialogTitle className="text-xl w-full bg-[#F6C334] p-4 text-center text-white stroke-black border-b-1 border-black">
          Thank your for your purchase!
        </AlertDialogTitle>
        <div className="flex flex-col justify-center items-center px-2 space-y-1">
          {
            items.map((item) => {
              return (
                <div>
                  <span className="text-[#FFCB38] stroke-black mr-1">
                    {item.gameItemName}
                  </span>
                  <span className="text-white stroke-black">
                    +{item.haveCount}
                  </span>
                </div>
              )
            })
          }
        </div>
        <AlertDialogFooter className="!flex my-2">
          <AlertDialogAction
            className="bg-[#FFCB38]"
            /* @ts-ignore */
            shadowColor="#A37F35"
            onClick={closeHandler}
          >
            Okay
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
