import { BaseInteractive, type BaseInteractiveProps } from '@/components/ui/BaseInteractiveButton';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { getPublicAsset } from '@/utils/getPublicAsset';
import clsx from 'clsx';
import Image from 'next/image';
type SideActionButtonsProps = BaseInteractiveProps & { className?: string, text: string | React.ReactNode, href?: string, image: string, imageSize?: number }
export const SideActionButtons: React.FC<SideActionButtonsProps> = ({ className, text, href, image, imageSize = 68, ...baseProps }) => {
    return <BaseInteractive {...baseProps}>
        <LocalizedLink
            prefetch={true}
            href={href}
            className={`${className} block active:translate-y-0.5 active:translate-x-0.5`}
        >
            <Image
                className="mx-auto"
                width={imageSize}
                height={imageSize}
                alt={typeof text === 'string' ? text : ''}
                src={image}
            />
            <div className="stroke-deepRed text-center text-sm text-[#FFF95C] font-bold -mt-[1.3rem] ">
                {text}
            </div>
        </LocalizedLink>
    </BaseInteractive>;
};