import { useAtom } from 'jotai';
import { currentMonthPassAtom } from '@/store/userInfo';
import {
  monthlyPassConfig,
  type MonthlyPassTier,
} from '@/constants/monthlyPass';

export const useMonthlyPass = () => {
  const [monthPassSubscription] = useAtom(currentMonthPassAtom);

  const monthPassBenefits = monthlyPassConfig[monthPassSubscription?.name];

  const hasMonthlyPassFreeEntry =
    monthPassSubscription && monthlyPassConfig[monthPassSubscription.name]?.ticket;

  const hasMonthlyPassFreeAds =
    monthPassSubscription && monthlyPassConfig[monthPassSubscription.name]?.ads;

  const getTierByName = (name: string): MonthlyPassTier | undefined => {
    return monthlyPassConfig[name];
  };

  return {
    monthPassSubscription,
    hasMonthlyPassFreeEntry,
    monthPassBenefits,
    getTierByName,
    hasMonthlyPassFreeAds,
  };
};

export default useMonthlyPass;
