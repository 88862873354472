import { useEffect } from 'react';
import thinkingdata from 'thinkingdata-browser';
import { useTWAEvent } from '@tonsolutions/telemetree-react';

export const useTrack = () => {
  const eventBuilder = useTWAEvent();

  const track = (eventName: string, propperties?: object) => {
    thinkingdata.track(eventName, propperties);
    eventBuilder.track(eventName, propperties);
  };

  return {
    track,
  }
};

export default useTrack;
