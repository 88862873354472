import request from '@/http/request';
import { useQuery } from '@tanstack/react-query';

export interface userTaskDaily {
  //上一次签到的详情
  typeId: string;
  consecutiveIndex: string; //连续签到次数
  status: string;
  createdTime: string;
}
export interface userTask {
  typeId: string;
  finishCount: string;
  rewardAmount: number;
  status: string;
  createdTime: string;
  category: string;
}

export interface TaskStatus {
  userTaskDailyDTO: userTaskDaily;
  userTaskInviteDTO: userTask;
  userTaskTgDTO: userTask;
  userTaskTWITTERDTO: userTask;
  userTaskTWITTERFOLLOWERDTO: userTask;
  userTaskTonWallet: userTask;
  userTaskOkxWallet: userTask;
}

export const fetchTasksStatus = () => {
  return request<TaskStatus>({
    url: '/api/mini-api/task/get-status',
    data: {},
    method: 'post',
  });
};

export const useTasksStatus = () => {
  return useQuery({
    queryKey: ['tasks'],
    queryFn: () => fetchTasksStatus(),
    staleTime: 0,
  });
};

export const postTaskClaim = (data: { taskId: string }) => {
  return request<{
    reward: string;
  }>({
    url: '/api/mini-api/task/claim',
    data,
    method: 'post',
  });
};

export const postTaskCheck = (data: { userTaskId: string }) => {
  return request<{
    finish: boolean;
  }>({
    url: '/api/mini-api/task/check-task-status',
    data,
    method: 'post',
  });
};

export interface TaskVerify {
  followTwitter: boolean;
  joinTelegramChannel: boolean;
  bindTwitter: boolean;
}

export const fetchTaskVerify = () => {
  return request<TaskVerify>({
    url: '/api/mini-api/task/verify-skip',
    data: {},
    method: 'post',
  });
};

export const useTaskVerify = () => {
  return useQuery({
    queryKey: ['tasks-verify'],
    queryFn: () => fetchTaskVerify().then((res) => res.data),
    staleTime: 0,
  });
};
