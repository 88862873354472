import React, { useState, useMemo, useEffect, useRef } from 'react';
import Image from 'next/image';
import { useAtom } from 'jotai';
import { cn } from '@/lib/utils';
import { useDraggable } from "react-use-draggable-scroll";
import {
  PotionItem,
  PotionPina,
  PotionDetail,
  usePotionPackages,
  PotionDetailResponse,
  fetchPotionDetail,
  fetchUpdatePotion
} from '@/apis/potion';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { PotionDetailDialog, BuyPotionDialog  } from '@/components/Potion';
import { useToast } from '@/components/ui/use-toast';
import { useMutation } from '@tanstack/react-query';
import useUserStatus from '@/hooks/useUserStatus';
import { currentPotionAtom, emptyPotion } from '@/store/runtime';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { Button } from '@/components/ui/button';
import { LoadingIcon } from '@/components/ui/loading';

const Tab = ({ selected, text, onClick }) => {
  return (
    <BaseInteractive onInteractiveClick={onClick}>
      <div className={cn("px-2 py-1 border-2 border-black text-black cursor-pointer rounded-2xl",
                         selected ?
                           "bg-white" :
                           "bg-[#DDC26E] text-black opacity-30")}
      >
        { text }
      </div>
    </BaseInteractive>
  );
};

const PotionCard = ({
  selected,
  potion,
  poisoned,
  openPotionDetailHandler,
  openBuyPotionHandler,
  onClick
}: {
  selected: boolean;
  potion: PotionItem;
  poisoned: boolean;
  openPotionDetailHandler: () => void;
  openBuyPotionHandler: () => void;
  onClick: () => void;
}) => {
  const { toast } = useToast();
  const potionLogo = `/images/potions/v1/lv${potion.potionId}.png`;
  const [currentPotion, setCurrentPotion] = useAtom(currentPotionAtom);
  const usePotionMutation = useMutation({
    mutationFn: (potionId:string|number) => {
      return Promise.resolve();
      // return fetchUpdatePotion({ potionId });
    },
    onSuccess: (data, potionId) => {
      if (poisoned) return;
      if (currentPotion.potionId === potionId.toString()) {
        setCurrentPotion(emptyPotion);
      } else {
        setCurrentPotion(potion);
      }
    },
    onError: ({ data }: any) => {
      toast({
        description: data.status.message,
        duration: 3000,
      });
    },
  });

  return (
    <BaseInteractive onInteractiveClick={onClick}>
      <div className={cn("relative flex flex-col w-[110px] items-center rounded-md p-0 text-black box-content",
                         selected ? "bg-[#F9DC8E]" : "bg-[#FFF4DA]")}
      >
        <BaseInteractive onInteractiveClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClick();
          openPotionDetailHandler();
        }}>
          <div className="absolute h-[24px] w-[24px] right-[-8px] top-[-8px] flex justify-center items-center text-sm border-2 border-black bg-[#FFCC53] rounded-full active:translate-y-0.5 active:translate-x-0.5 z-10">
            ?
          </div>
        </BaseInteractive>
        <BaseInteractive onInteractiveClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if (Number(potion.haveCount) > 0) {
            usePotionMutation.mutate(potion.potionId);
          } else {
            onClick();
            openBuyPotionHandler();
          }
        }}>
          <div className="relative w-full h-full rounded-md border-2 border-[#C8AA73] outline-0 overflow-hidden">
            <div className="flex w-full py-1 justify-center items-center w-auto text-sm bg-[#C9D54B] border-b-2 border-[#C8AA73]">
              <Image className="w-[18px] h-[18px] mr-1" src="/images/bag/usdt.png" alt="usdt" width={24} height={24} />
              <div> {potion.usdt} </div>
            </div>
            <div className="relative flex justify-center items-center w-full  cursor-pointer active:translate-y-0.5 active:translate-x-0.5">
              <Image
                className="w-[64px] h-[64px]"
                src={potionLogo}
                alt={potion.name}
                width={48} height={48}
              />
              <div className="absolute flex justify-center items-center w-full text-xs text-white stroke-black w-[48px] h-[48px] left-[-8px] top-[20px] uppercase">
                lv{potion.potionId}
              </div>
              <div className="absolute flex justify-center items-center min-w-[36px] px-0 py-0 text-xs border-2 border-black bg-[#FFCC53] bottom-[-12px] ml-[-12px] rounded-full z-10">
                X{potion.itemCount}
              </div>
            </div>
            <div className="flex justify-center items-center text-center w-full h-[48px] text-break text-base text-balance my-4">
              {potion.name}
            </div>
          </div>
        </BaseInteractive>
        <div className="absolute flex bottom-[-24px] w-[48px] h-[48px] justify-center items-center cursor-pointer active:translate-y-0.5 active:translate-x-0.5">
          {
            Number(potion.haveCount) > 0 ? (
              currentPotion?.name === potion.name ? (
                <BaseInteractive onInteractiveClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  usePotionMutation.mutate(potion.potionId);
                }}>
                  <Image
                    className="accordion-up w-[28px] h-[28px] border-[2px] border-black rounded-full"
                    src={getPublicAsset('/images/potions/correct.png')}
                    alt="use this potion"
                    width={64}
                    height={64}
                  />
                </BaseInteractive>
              ) : poisoned ? null : (
                <BaseInteractive onInteractiveClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  usePotionMutation.mutate(potion.potionId);
                }}>
                  <div className="relative flex justify-start items-center px-1 min-w-[80px] h-[28px] text-sm border-2 border-black bg-[#FFF] rounded-xl">
                    {
                      usePotionMutation.isPending ? (
                        <LoadingIcon />
                      ) : (
                        <>
                          <div className="w-[50px] text-center"> {potion.haveCount} </div>
                          <div className="flex absolute justify-center items-center w-auto h-[30px] top-[-3px] left-[54px] text-sm px-1 border-2 border-black bg-[#C4D719] rounded-xl">
                            Use
                          </div>
                        </>
                      )
                    }
                  </div>
                </BaseInteractive>
              )
            ) : (
              <BaseInteractive onInteractiveClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onClick();
                openBuyPotionHandler();
              }}>
                <div className="flex justify-center items-center w-[48px] h-[32px] text-sm border-2 border-black bg-[#C4D719] rounded-3xl">
                  <Image className="w-[16px] h-[16px]" src="/images/potions/plus.svg" alt="plus" width={24} height={24} />
                </div>
              </BaseInteractive>
            )
          }
        </div>
      </div>
    </BaseInteractive>
  );
};

export const StartGameUsePotion = ({
  className = "",
  onPotionDetailResponse,
  ...props
}) => {
  const refTab = useRef();
  const refCard = useRef();
  const { toast } = useToast();
  const { events: tabEvents } = useDraggable(refTab);
  const { events: cardEvents } = useDraggable(refCard);
  const [selectedTab, setSelectedTab] = useState('');
  const [selectedPotion, setSelectedPotion] = useState(null);
  const [currentPotion, setCurrentPotion] = useAtom(currentPotionAtom);
  const [potionDetail, setPotionDetail] = useState(null);
  const [potionPinas, setPotionPinas] = useState(null);
  const [openPotionDetailDialog, setOpenPotionDetailDialog] = useState(false);
  const [openBuyPotionDialog, setOpenBuyPotionDialog] = useState(false);
  const { data: userStatus } = useUserStatus();
  const { data: potionPackages, refetch: reloadPotions } = usePotionPackages();
  const { gameStatus } = userStatus;
  const { journey: { journeyPotion }} = gameStatus;

  const getPotionMutation = useMutation({
    mutationFn: (potionId:string|number) =>
      fetchPotionDetail({ potionId }),
    gcTime: 0,
    onError: ({ data }: any) => {
      toast({
        description: data.status.message,
        duration: 3000,
      });
    },
    onSuccess: (data) => {
      setPotionDetail(data?.potionDetail);
      setPotionPinas(data?.potionPinas);
      onPotionDetailResponse(data);
    },
  });

  const potions = useMemo(() => {
    if (!potionPackages) return [];
    let p = [];
    Object.keys(potionPackages.map).map((key) => {
      p = p.concat(potionPackages.map[key]);
    });
    return p;
  }, [potionPackages]);

  const tabs = useMemo(() => {
    if (!potionPackages) return [];
    const r = Object.keys(potionPackages?.map);
    return r ? r : [];
  }, [potionPackages]);

  const potionList = useMemo(() => {
    if (!potionPackages || !selectedTab) return [];
    return potionPackages?.map[selectedTab];
  }, [selectedTab, potionPackages]);

  useEffect(() => {
    if (tabs[0]) {
      setSelectedTab(tabs[0]);
    }
  }, [tabs]);

  useEffect(() => {
    if (selectedPotion?.potionId) {
      getPotionMutation.mutate(selectedPotion?.potionId)
    }
  }, [selectedPotion]);

  useEffect(() => {
    if (!journeyPotion) {
      setCurrentPotion(emptyPotion);
    } else {
      const p = potions.find(potion => {
        return potion.name === journeyPotion.name;
      });
      setCurrentPotion(p ? p : emptyPotion);
    }
  }, [journeyPotion, potions]);

  // we need request potion once to fetch the default pina grab number
  useEffect(() => {
    getPotionMutation.mutate(1);
  }, [])

  return (
    <>
      <div
        className={`flex flex-col font-comicbd ${className}`}
        {...props}
      >
        <div
          className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
          ref={refTab}
          {...tabEvents}
        >
          {
            tabs.map((tab) => {
              return (
                <Tab
                  key={tab}
                  selected={tab === selectedTab}
                  text={tab}
                  onClick={() => setSelectedTab(tab)}
                />
              );
            })
          }
        </div>
        <div
          className="flex mt-2 mb-2 pt-2 pb-4 px-2 max-w-xl space-x-2 bg-[#DDC26E30] overflow-x-scroll scrollbar-hide rounded-xl"
          ref={refCard}
          {...cardEvents}
        >
          {
            potionList.map((potion) => {
              return (
                <div key={potion.name} className="mt-2 mb-2">
                  <PotionCard
                    key={potion.name}
                    selected={false && potion === selectedPotion}
                    potion={potion}
                    poisoned={false && !!gameStatus.journey.journeyPotion}
                    openPotionDetailHandler={() => { setOpenPotionDetailDialog(true) }}
                    openBuyPotionHandler={() => { setOpenBuyPotionDialog(true)}}
                    onClick={() => setSelectedPotion(potion)}
                  />
                </div>
              );
            })
          }
        </div>
        <div className="relative">
          {
            // getPotionMutation.isPending ? (
            //   <div className="absolute flex justify-center items-center stroke-black w-full h-[200px] left-0 bg-[#FFF4DA] opacity-90 z-10">
            //     Pending...
            //   </div>
            // ) : null
          }
        </div>
      </div>

      <PotionDetailDialog
        show={openPotionDetailDialog}
        pending={getPotionMutation.isPending}
        onClose={() => setOpenPotionDetailDialog(false)}
        potion={selectedPotion}
        potionDetail={potionDetail}
        potionPinas={potionPinas}
      />

      <BuyPotionDialog
        show={openBuyPotionDialog}
        potion={selectedPotion}
        PackagePicture={(
          <div className="relative w-[64px] h-[64px]">
            <Image
              className="absolute left-0 top-0 w-[64px] h-[64px]"
              src={getPublicAsset(`/images/potions/v1/lv${selectedPotion?.potionId}.png`)}
              alt={selectedPotion?.name}
              width={56}
              height={56}
            />
            <span className="absolute bottom-0 px-2 w-[64px] text-center text-sm text-white stroke-black">
              +{selectedPotion?.itemCount}
            </span>
          </div>
        )}
        paymentSuccess={() => {
          reloadPotions();
        }}
        closeHandler={() => {
          setOpenBuyPotionDialog(false);
        }}
      />
    </>
  );
};

export default StartGameUsePotion;
