import { atom } from 'jotai';
import {
  type UserModel,
  type TaskInfo,
  GameItemDTOList,
  UserGameStatusDTO,
  ReduceMiningInfo,
  CurrentMonthPass,
} from '@/apis/login';
import { getLocalStorage, setLocalStorage } from '@/utils/localStoreHelper';
import { EasterEggDataDTO } from '@/apis/easterEgg';

export const userTaskInfoAtom = atom<TaskInfo>({
  totalCount: 0,
  finishCount: 0,
  percent: 0,
  popWindows: false,
});

export const userInfoAtom = atom<UserModel>({
  tgId: '',
  name: '',
  headerUrl: '',
  registerIp: '',
  registerCity: '',
  registerCountry: '',
  registerTime: '',
  gold: '',
  bananaCount: '',
  depositAmount: '',
  bananaOnlineId: '',
  claimEasterEgg: false,
  skipToday: false,
  skipNum: 0,
});

export const userGameStatus = atom<UserGameStatusDTO>({
  playTimes: '',
  successTimes: '',
  lastSpinTime: '',
  playFreeTimes: '',
  reviveEarnMethod: [],
  ticketEarnMethod: [],
  spinTimes: '',
  journey: {
    index: undefined,
    difficultyCoefficient: undefined,
    nextPrice: '',
    nextRewards: '',
    currentLevel: undefined,
    isLastLevel: false,
  },
  "max_level": 2,
  "max_journey": 35,
});

export const userEasterEggInfoAtom = atom<EasterEggDataDTO>({
  tgId: '',
  pass: '',
  expireTime: '',
  users: [],
  myBananaId: '',
  myGold: '',
  link: '',
});

export const reduceMiningInfoAtom = atom<ReduceMiningInfo>({
  cycleIndex: '',
  percent: '',
});

export const gameItemDTOListAtom = atom<GameItemDTOList[]>([]);

export const currentMonthPassAtom = atom<CurrentMonthPass>({
  durationDays: 0,
  expirationTime: '',
  id: 0,
  name: '',
  payId: '',
  star: 0,
  usdt: '',
});

const tokenAtom = atom(getLocalStorage('token') ?? '');
export const tokenManager = atom(
  (get) => get(tokenAtom),
  (get, set, newToken: string) => {
    set(tokenAtom, newToken);
    setLocalStorage('token', newToken);
  },
);

export const cloudflareTurnstileTokenAtom = atom("turn off");
