'use client';
import { PropsWithChildren, useState, useEffect, useMemo } from 'react';
import {
  useMiniApp,
  useLaunchParams,
  useThemeParams,
  useViewport,
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  useClosingBehavior,
  useSwipeBehavior,
} from '@telegram-apps/sdk-react';

import { store } from '@/store';
import { tokenManager } from '@/store/userInfo';
import { useAtom } from 'jotai';

export const InitializeTelegramMiniApp = ({ children }: PropsWithChildren) => {
  const [token, setToken] = useAtom(tokenManager, { store: store });
  const swipeBehavior = useSwipeBehavior();
  const closingBehavior = useClosingBehavior();
  const lp = useLaunchParams();
  const miniApp = useMiniApp();
  const themeParams = useThemeParams();
  const viewport = useViewport();

  useEffect(() => {
    setToken(lp.initDataRaw);
  }, []);

  useEffect(() => {
    miniApp.ready();
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  useEffect(() => {
    if (viewport) viewport.expand();
    return viewport && bindViewportCSSVars(viewport);
  }, [viewport]);

  // useEffect(() => {
  //   swipeBehavior?.disableVerticalSwipe();
  // }, [swipeBehavior]);

  useEffect(() => {
    closingBehavior.enableConfirmation();
  }, [closingBehavior]);

  // Create a new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
  // const [location, reactNavigator] = useIntegration(navigator);

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  return <>{children}</>;
};

export default InitializeTelegramMiniApp;
