export interface MonthlyPassTier {
  type: string;
  price: number;
  stars: number;
  expeditionMultiplier: number;
  miningMultiplier: number;
  ticket: boolean;
  ads: boolean;
  avatar: boolean;
}

export const monthlyPassConfig: Record<string, MonthlyPassTier> = {
  Basic: {
    type: 'Basic',
    price: 0.99,
    stars: 50,
    expeditionMultiplier: 2,
    miningMultiplier: 2,
    ticket: false,
    ads: true,
    avatar: false,
  },
  Advanced: {
    type: 'Advanced',
    price: 4.99,
    stars: 250,
    expeditionMultiplier: 3,
    miningMultiplier: 3,
    ticket: true,
    ads: true,
    avatar: true,
  },
  Premium: {
    type: 'Premium',
    price: 9.99,
    stars: 500,
    expeditionMultiplier: 5,
    miningMultiplier: 5,
    ticket: true,
    ads: false,
    avatar: true,
  },
};

 
