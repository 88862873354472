'use client';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import {
  AlertDialogTitle,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
} from '@/components/ui/alert-dialog';
import { useToast } from '@/components/ui/use-toast';
import { ShowPromiseResult } from '@/types/adsgram';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { Button } from '@/components/ui/button';
import { AdsButton } from '@/components/ui/AdsButton';
import { ShareButton } from '@/components/ui/ShareButton';
import isOdd from '@/utils/number/isOdd';
import { Items } from '@/constants/Items';
import { useLocale } from 'next-intl';
import { fetchGetGameItem, EventId } from '@/apis/game';
import useUserStatus from '@/hooks/useUserStatus';
import { useAtom, useAtomValue } from 'jotai';

import { isAdPlayingAtom } from '@/store/runtime';
import { userGameStatus } from '@/store/userInfo';
import { useTrack } from '@/hooks/useTrack';

type TicketDialogProps = {
  show: boolean;
  onClose: () => void;
  handleContinueGameStart: () => void;
};

const GetTicketButton: React.FC<{
  text: string;
  onShareSuccess: () => void;
  onAdsReward: () => void;
  onAdsError: (result: ShowPromiseResult) => void;
}> = ({ text, onShareSuccess, onAdsReward, onAdsError }) => {
  const userGame = useAtomValue(userGameStatus);
  const isShare = useMemo(() => {
    return userGame?.ticketEarnMethod[0] === EventId.share;
  }, [userGame])
  const ButtonComponent = useMemo(() => {
    const ButtonComponent = isShare ? ShareButton : AdsButton;
    return ButtonComponent;
  }, [isShare]);

  return (
    <ButtonComponent
      text={text}
      onShareSuccess={onShareSuccess}
      onAdsReward={!isShare ? onAdsReward : () => {}}
      onAdsError={onAdsError}
      className={`w-[190px] !mx-0 h-[50px] text-base ${
        isShare ? 'bg-[#8DCAFF]' : 'bg-[#FFD33B]'
      }`}
      shadowColor={isShare ? '#8DCAFF' : '#FFD33B'}
      trackData={{
        scene: 'get_item_ticket',
        entrance: 'startgame',
      }}
    />
  );
  // return (
  //   <ShareButton
  //     text={text}
  //     onShareSuccess={onShareSuccess}
  //     className="w-[190px] !mx-0 h-[50px] text-base bg-[#8DCAFF]"
  //     shadowColor="#8DCAFF"
  //   />
  // );
};

export const TicketDialog: React.FC<TicketDialogProps> = ({
  show,
  onClose,
  handleContinueGameStart,
}) => {
  const { track } = useTrack();
  const router = useRouter();
  const locale = useLocale();
  const { toast } = useToast();
  const { data, refetchUserStatus } = useUserStatus();
  // const [isClickGetTicket, setIsClickGetTicket] = useState(false);
  const [isAdPlaying] = useAtom(isAdPlayingAtom);

  const { mutate: getGameItem, isPending } = useMutation({
    mutationFn: fetchGetGameItem,
    onError: ({ data }: any) => {
      console.error(data);
      // throw new Error(JSON.stringify(data));
      toast({
        description: 'Oops! Something went wrong, try again later.🙏',
        duration: 3000,
      });
    },
    onSuccess: () => {
      track('get_item', {
        item_id: Items['ticket'].key,
        item_name: 'ticket',
      });
      refetchUserStatus();
      handleContinueGameStart();
    },
  });

  const onAdsReward = useCallback(() => {
    getGameItem({
      eventId: EventId.AD,
      gameItemId: Items['ticket'].key,
    });
  }, [getGameItem]);

  const handleToStore = useCallback(() => {
    track('buy_ticket', {
      from: 'playGame',
    });
    router.push(`${locale}/store`);
  }, [router, locale]);

  const onShareSuccess = useCallback(() => {
    getGameItem({
      eventId: EventId.share,
      gameItemId: Items['ticket'].key,
    });
  }, [getGameItem, handleContinueGameStart]);

  return (
    <AlertDialog open={show && !isAdPlaying}>
      <AlertDialogTitle />
      <AlertDialogContent
        className="rounded-[16px] w-[350px] h-[370px] px-[0] py-[0] shadow-[7px_7px_0px_rgba(0,0,0,00.8)]"
        enable3DBorder={false}
      >
        <div className="relative bg-[#FFF4DA] rounded-[16px] w-full h-full border-[2px] border-black shadow-[6px_6px_0px_#EE9B40]">
          <BaseInteractive onInteractiveClick={onClose}>
            <div className="closeButton absolute top-[-17px] right-[-18px] w-[40px] h-[40px] z-20 active:translate-y-0.5 active:translate-x-0.5">
              <Image
                fill
                src={getPublicAsset('/images/lobby/close.png')}
                alt="close"
              />
            </div>
          </BaseInteractive>
          <div>
            <Image
              width={420}
              height={377}
              src={getPublicAsset('/images/lobby/ticketDialog.png')}
              alt=""
              className="absolute top-[-160px] left-0 scale-[0.8]"
            />
          </div>
          <div className="text-center mb-5 font-comicbd mt-[70px]">
            <h2 className="text-4xl text-white stroke-1 stroke-black">
              You've run out of tickets.
            </h2>
          </div>
          <AlertDialogFooter className="flex flex-col items-center justify-center pb-4 gap-5">
            {/* {!isClickGetTicket ? (
              <GetTicketButton
                playTimes={playTimes}
                text="Start Going"
                onShareSuccess={onShareSuccess}
                onAdsReward={onAdsReward}
                onAdsError={(result: ShowPromiseResult) =>
                  toast({
                    description:
                      'Oops! Something went wrong, try again later.🙏',
                    duration: 3000,
                  })
                }
              />
            ) : (
              <Button
                onClick={handleContinueGameStart}
                className="w-[190px] flex justify-center items-center gap-2 h-[50px] flex-shrink-0 bg-[#F6C334] !mx-0"
                shadowColor="#F6C334"
              >
                <span className="text-base text-white stroke-1 stroke-black">
                  {isPending ? 'Launching' : 'Going'}
                </span>
              </Button>
            )} */}
            {
              <GetTicketButton
                text="Watch ads"
                onShareSuccess={onShareSuccess}
                onAdsReward={onAdsReward}
                onAdsError={(result: ShowPromiseResult) => {
                  console.error(result);
                  toast({
                    description:
                      'Oops! Something went wrong, try again later.🙏',
                    duration: 3000,
                  });
                }}
              />
            }
            <Button
              onClick={handleToStore}
              className="w-[190px] flex justify-center items-center gap-2 h-[50px] flex-shrink-0 bg-[#BDD10A] !mx-0"
              shadowColor="#BDD10A"
            >
              <Image
                width={30}
                height={30}
                src={getPublicAsset('/images/lobby/store-icon.png')}
                alt=""
              />
              <span className="text-base text-white stroke-1 stroke-black">
                Buy Tickets
              </span>
            </Button>
          </AlertDialogFooter>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};
