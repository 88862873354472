import request from '@/http/request';
import { useQuery } from '@tanstack/react-query';

export interface MonthPassTabs {
  monthPassList: {
    id: number;
    payId: string;
    name: 'Basic' | 'Advanced' | 'Premium';
    durationDays: number;
    usdt: string;
    star: number;
  }[];
}
export const fetchMonthPassTabs = () => {
  return request<MonthPassTabs>({
    url: '/api/mini-api/game/game-month-pass-packages',
    data: {},
    method: 'post',
    suppressWarning: false,
  });
};

export const useMonthPassTabs = () => {
  return useQuery({
    queryKey: ['monthPassTabs'],
    queryFn: () => fetchMonthPassTabs(),
  });
};
