




import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogAction, AlertDialogTitle, AlertDialogCancel } from '@/components/ui/alert-dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import Card from '@/components/pages/bag/Card';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import RewardItem from './RewardItem';
import { useMonthPassTabs } from '@/apis/monthPass';
import { cn } from "@/lib/utils";
import { useEffect, useState, useMemo } from 'react';
import PaymentDialog from '@/components/ui/PaymentDialog';
import { Button } from '@/components/ui/button';
import { motion, AnimatePresence } from 'framer-motion';
import { fetchPayList } from '@/apis/payment';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { currentMonthPassAtom } from '@/store/userInfo';
import useMonthlyPass from '@/hooks/useMonthlyPass'
import { useAtom } from 'jotai';
import dayjs from 'dayjs';
const coinIcon = getPublicAsset('/images/monCard/coin.png');
const coinsIcon = getPublicAsset('/images/monCard/coins.png');
const pinaIcon = getPublicAsset('/images/monCard/pina.png');
const tickIcon = getPublicAsset('/images/monCard/tick.png');
const ellipseIcon = getPublicAsset('/images/monCard/ellipse.png');
const adsIcon = getPublicAsset('/images/monCard/ads.png');
const basicIcon = getPublicAsset('/images/monCard/basic.png');
const advancedIcon = getPublicAsset('/images/monCard/advanced.png');
const premiumIcon = getPublicAsset('/images/monCard/premium.png');
const closeIcon = getPublicAsset('/images/lobby/close.png');


const PaymentSuccessDialog = ({ open, onOpenChange }: { open: boolean, onOpenChange: (open: boolean) => void }) => {
    const [currentMonthPass, setCurrentMonthPass] = useAtom(currentMonthPassAtom);
    return <AlertDialog open={open} onOpenChange={onOpenChange}>
        <AlertDialogContent>
            <AlertDialogTitle>Payment Success</AlertDialogTitle>
            <div>
                currentMonthPass: {currentMonthPass?.name}
                durationDays: {currentMonthPass?.durationDays}
                expirationTime: {currentMonthPass?.expirationTime}
                star: {currentMonthPass?.star}
                usdt: {currentMonthPass?.usdt}
            </div>
            <AlertDialogAction onClick={() => {
                onOpenChange(false);
            }}>
                Close
            </AlertDialogAction>
        </AlertDialogContent>
    </AlertDialog>
}

const PayButton = ({ price, onClick }: { price: number, onClick: () => void }) => (
    <Button
        onClick={onClick}
        disableHoverAndActive
        shadowColor='#737B31'
        className='bg-[#BDD10A] absolute left-1/2 -translate-x-1/2 bottom-0 right-0 inline-block text-lg w-[150px]  py-2 text-white stroke-black font-comicbd'
    >
        $ {price}
    </Button>
);

export default function MonCardDialog({ open, onOpenChange }: { open: boolean, onOpenChange: (open: boolean) => void }) {
    const [tab, setTab] = useState('Basic');
    const { data: monthPassTabs } = useMonthPassTabs();
    const iconMap = useMemo(() => ({
        Basic: basicIcon,
        Advanced: advancedIcon,
        Premium: premiumIcon,
    }), []);
    const [PaymentDialogShow, setPaymentDialogShow] = useState(false);
    const [PaymentSuccessDialogShow, setPaymentSuccessDialogShow] = useState(false);
    const [currentMonthPass, setCurrentMonthPass] = useAtom(currentMonthPassAtom);
    const { getTierByName } = useMonthlyPass();
    const closeHandler = () => {
        setPaymentDialogShow(false);
    }
    const TabStroke = {
        Basic: 'stroke-deepBlue',
        Advanced: 'stroke-purple',
        Premium: 'stroke-chocolate',
    }

    const paymentSuccess = () => {
        setPaymentDialogShow(false);
    }
    const PackagePicture = () => {
        return <div>PackagePicture</div>;
    }
    const [payData, setPayData] = useState<{ name: string, payId: string, star: number, usdt: number } | null>(null);
    return <AlertDialog open={open} onOpenChange={onOpenChange}>
        <AlertDialogContent className='max-w-[400px] p-0 m-0 bg-transparent border-none' showOverlay={false}>
            {/* close */}
            <BaseInteractive onInteractiveClick={() => {
                onOpenChange(false)
            }}>
                <Image
                    priority
                    src={closeIcon}
                    alt="close"
                    width={40}
                    height={40}
                    className='absolute -right-3 -top-3 z-20'
                />
            </BaseInteractive>
            {/* hearCard */}
            <AnimatePresence mode="wait">
                <motion.div
                    key={tab}
                    initial={{ opacity: 0, rotate: -45, x: -100, transformOrigin: "left bottom" }}
                    animate={{ opacity: 1, rotate: 0, x: 0 }}
                    exit={{ opacity: 0, rotate: 15, x: 0 }}
                    transition={{
                        duration: 0.3,
                        // ease: [0.175, 0.885, 0.32, 1] // easeOutBack 效果
                    }}
                    className="w-full absolute left-0 bottom-[76%] z-10"
                >
                    <Image
                        src={iconMap[tab as keyof typeof iconMap]}
                        priority
                        alt={tab.toLowerCase()}
                        width={614}
                        height={400}
                    />
                    {tab === currentMonthPass?.name ?
                        <div className='absolute top-[22%] left-[4%] z-10 -rotate-[7deg] font-comicbd'>
                            <div className='text-center'>
                                Valid until
                            </div>
                            <div className='bg-[#2B2B2B] text-white px-4 py-1 rounded-[4px] relative 
                            after:content-[""] after:absolute after:right-[-14px] after:top-0 after:border-t-[16px] after:border-t-transparent after:border-l-[16px] after:border-l-[#2B2B2B] after:border-b-[16px] after:border-b-transparent'>
                                {dayjs(currentMonthPass?.expirationTime).format('YYYY-MM-DD')}
                            </div>
                        </div> :
                        <div className='absolute top-[22%] left-[10%] z-10 -rotate-[7deg] font-comicbd'>
                            <div className={`text-[#FFD571]  font-bold text-2xl ${TabStroke[tab]}`}>MONTHLY PASS</div>
                            <div className='text-black text-sm'>
                                Buy Monthly Privileges
                            </div>
                            <div className='text-black text-sm'>
                                to unlock more rewards
                            </div>
                        </div>}
                    <div className='w-[55px]   text-center absolute text-xs top-[9%] right-[13%] z-10 -rotate-[28deg] font-comicbd'>
                        {tab}
                    </div>
                </motion.div>
            </AnimatePresence>
            {/* bodyCard */}
            <Card
                className="relative  before:content-[''] before:z-10 before:-top-[3px] before:bg-black before:rounded-xl before:absolute before:inset-[0px] before:rounded-inherit before:pointer-events-none before:[mask-image:radial-gradient(45%_50px_at_50%_-10px,transparent_98%,black_100%)]"
                style={{
                    maskImage: 'radial-gradient(45% 50px at 50% -10px, transparent 98%, black 100%)',
                    WebkitMaskImage: 'radial-gradient(45% 50px at 50% -10px, transparent 98%, black 100%)'
                }}
                bgColor='bg-[#F4F1FF] border-t-none'
                shadowColor='bg-[#743EDC]'
            >
                <Tabs value={tab} onValueChange={setTab} defaultValue="Basic" className="m-2 mt-14  ">
                    <TabsList className="grid w-full grid-cols-3 p-0 bg-[#A8AAD0]  rounded-lg overflow-hidden border-0 border-[#626066] h-11 items-center">
                        {['Basic', 'Advanced', 'Premium'].map((tab) => (
                            <TabsTrigger
                                key={tab}
                                value={tab}
                                className={cn(
                                    " text-white relative font-bold h-full flex items-center stroke-gray justify-center border-transparent border-2 ",
                                    "data-[state=active]:text-black",
                                    "data-[state=active]:bg-[#FFD66C]",
                                    "data-[state=active]:border-black",
                                    "data-[state=active]:stroke-white",
                                    "data-[state=active]:after:opacity-0",
                                    'after:content-[""] after:absolute after:right-[-4px] after:top-1/2 after:-translate-y-1/2 after:w-[2px] after:h-[70%] after:bg-white/50'

                                )}
                            >
                                {tab}
                            </TabsTrigger>
                        ))}
                    </TabsList>

                    <AnimatePresence mode="wait">
                        <motion.div
                            key={tab}
                            initial={{ opacity: 0, y: 5 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -5 }}
                            transition={{
                                duration: 0.18,
                                ease: [0.4, 0, 0.2, 1] // material design easing
                            }}
                        >
                            <TabsContent className='h-[320px] relative' value="Basic">
                                <div className='space-y-1'>
                                    <RewardItem
                                        icon={coinIcon}
                                        label="Pina Coins on per expendition"
                                        value={getTierByName('Basic').expeditionMultiplier}
                                    />
                                    <RewardItem
                                        icon={pinaIcon}
                                        label="Mining Rewards for Equippied Pinas per hour"
                                        value={getTierByName('Basic').miningMultiplier}
                                    />
                                </div>

                                {currentMonthPass?.name !== 'Basic' && <PayButton
                                    price={getTierByName('Basic').price}
                                    onClick={() => {
                                        setPayData({
                                            name: 'Basic',
                                            payId: monthPassTabs?.data.monthPassList.find(item => item.name === 'Basic')?.payId,
                                            star: getTierByName('Basic').stars,
                                            usdt: getTierByName('Basic').price,
                                        });
                                        setPaymentDialogShow(true);
                                    }}
                                />}

                            </TabsContent>
                            <TabsContent className='h-[320px] relative' value="Advanced">
                                <div className='space-y-1'>
                                    <RewardItem
                                        icon={coinIcon}
                                        label="Pina Coins on per expendition"
                                        value={getTierByName('Advanced').expeditionMultiplier}
                                    />
                                    <RewardItem
                                        icon={pinaIcon}
                                        label="Mining Rewards for Equippied Pinas per hour"
                                        value={getTierByName('Advanced').miningMultiplier}
                                    />
                                    <RewardItem
                                        icon={tickIcon}
                                        label="Unlimited Tickets"
                                    />
                                </div>

                                {currentMonthPass?.name !== 'Advanced' && <PayButton
                                    price={getTierByName('Advanced').price}
                                    onClick={() => {
                                        setPayData({
                                            name: 'Advanced',
                                            payId: monthPassTabs?.data.monthPassList.find(item => item.name === 'Advanced')?.payId,
                                            star: getTierByName('Advanced').stars,
                                            usdt: getTierByName('Advanced').price,
                                        });
                                        setPaymentDialogShow(true);
                                    }}
                                />}
                            </TabsContent>

                            <TabsContent className='h-[320px] relative' value="Premium">
                                <div className='space-y-1'>
                                    <RewardItem
                                        icon={coinIcon}
                                        label="Pina Coins on per expendition"
                                        value={getTierByName('Premium').expeditionMultiplier}
                                    />
                                    <RewardItem
                                        icon={pinaIcon}
                                        label="Mining Rewards for Equippied Pinas per hour"
                                        value={getTierByName('Premium').miningMultiplier}
                                    />
                                    <RewardItem
                                        icon={tickIcon}
                                        label="Unlimited Tickets"
                                    />
                                    <RewardItem
                                        icon={ellipseIcon}
                                        label="Special Avatar"
                                    />
                                    <RewardItem
                                        icon={adsIcon}
                                        label="Skip all ads"
                                    />
                                </div>
                                {currentMonthPass?.name !== 'Premium' && <PayButton
                                    price={getTierByName('Premium').price}
                                    onClick={() => {
                                        setPayData({
                                            name: 'Premium',
                                            payId: monthPassTabs?.data.monthPassList.find(item => item.name === 'Premium')?.payId,
                                            star: getTierByName('Premium').stars,
                                            usdt: getTierByName('Premium').price,
                                        });
                                        setPaymentDialogShow(true);
                                    }}
                                />}
                            </TabsContent>
                        </motion.div>
                    </AnimatePresence>
                </Tabs>
            </Card>
            <AnimatePresence mode="wait">
                <motion.div
                    key={tab}
                    initial={{ scale: 0.8, opacity: 0, y: -20 }}
                    animate={{ scale: 1, opacity: 1, y: 0 }}
                    exit={{ scale: 0.8, opacity: 0, y: 20 }}
                    transition={{
                        duration: 0.3,
                        ease: "easeOut"
                    }}
                    className='absolute top-[2.5%] left-[34%] z-20 rotate-[14deg]'
                >
                    <Image
                        src={coinsIcon}
                        priority
                        alt={tab.toLowerCase()}
                        width={160}
                        height={41}
                    />
                </motion.div>
            </AnimatePresence>
            {/* paymentDialog */}
            <PaymentDialog
                open={PaymentDialogShow}
                closeHandler={closeHandler}
                paymentSuccess={paymentSuccess}
                PackagePicture={PackagePicture}
                packageName={payData?.name}
                payId={payData?.payId}
                star={payData?.star}
                usdt={payData?.usdt}
            />
            {/* paymentSuccessDialog */}
            <PaymentSuccessDialog
                open={PaymentSuccessDialogShow}
                onOpenChange={setPaymentDialogShow}
            />
        </AlertDialogContent>
    </AlertDialog >
}