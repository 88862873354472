import React, { useState, useEffect, useMemo } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { useQuery, useMutation } from '@tanstack/react-query';
import { fetchTasksStatus, postTaskClaim } from '@/apis/task';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { getPublicAsset } from '@/utils/getPublicAsset';
import CountUp from 'react-countup';
import { Items } from '@/constants/Items';
import { AlertDialog, AlertDialogContent } from '@/components/ui/alert-dialog';
import { useBagList } from '@/apis/bag';
import { BaseInteractive } from '../ui/BaseInteractiveButton';
import { formatLargeNumber } from '@/utils/number/formatLargeNumber';

const mockdata = {
  userTaskDailyDTO: {
    typeId: '1',
    consecutiveIndex: '',
    status: 'UNFINISH',
    createdTime: '2024-12-11 11:59:31',
    category: '',
  },
  userTaskInviteDTO: {
    typeId: '2',
    finishCount: '4',
    rewardAmount: '25000',
    status: 'FINISH',
    createdTime: '2024-08-23 03:46:37',
    category: 'Basic',
    updatedTime: [2024, 11, 16, 11, 5, 2, 593893000],
  },
  userTaskTgDTO: {
    typeId: '3',
    finishCount: '0',
    rewardAmount: '10000',
    status: 'CLAIMED',
    createdTime: '2024-08-23 03:46:37',
    category: 'Required',
    updatedTime: [2024, 12, 11, 12, 6, 12, 899110000],
  },
  userTaskTWITTERDTO: {
    typeId: '4',
    finishCount: '0',
    rewardAmount: '10000',
    status: 'CLAIMED_UNFINISH',
    createdTime: '2024-08-23 03:46:37',
    category: 'Required',
    updatedTime: [2024, 10, 7, 1, 37, 54, 502239000],
  },
  userTaskTWITTERFOLLOWERDTO: {
    typeId: '5',
    finishCount: '0',
    rewardAmount: '30000',
    status: 'UNFINISH',
    createdTime: '2024-08-23 03:46:37',
    category: 'Required',
    updatedTime: [2024, 10, 22, 9, 40, 30, 861907000],
  },
  userTaskOnlineDTO: {
    typeId: '6',
    finishCount: '0',
    rewardAmount: '2166428',
    status: 'CLAIMED',
    createdTime: '2024-08-23 03:46:37',
    category: '',
    updatedTime: [2024, 12, 11, 1, 30, 0, 703104000],
  },
  userTaskTonWallet: {
    typeId: '7',
    finishCount: '0',
    rewardAmount: '30000',
    status: 'UNFINISH',
    createdTime: '2024-08-23 03:46:37',
    category: 'Required',
    updatedTime: [2024, 8, 23, 4, 57, 2, 462887000],
  },
  userTaskOkxWallet: {
    typeId: '8',
    finishCount: '0',
    rewardAmount: '30000',
    status: 'UNFINISH',
    createdTime: '2024-10-09 18:24:23',
    category: 'Special',
  },
  userTaskBot: {
    typeId: '9',
    finishCount: '0',
    rewardAmount: '10000',
    status: 'CLAIMED',
    createdTime: '2024-10-16 14:00:20',
    category: 'Required',
    updatedTime: [2024, 12, 11, 12, 6, 12, 899110000],
  },
};

const ImgMap: Record<string, string> = {
  undo: getPublicAsset('/images/spin/revoke.png'),
  cells: getPublicAsset('/images/spin/+.png'),
  shuffle: getPublicAsset('/images/spin/random.png'),
  ticket: getPublicAsset('/images/spin/ticket2x.png'),
  revive: getPublicAsset('/images/spin/resurrection.png'),
  hint: getPublicAsset('/images/spin/Tips.png'),
};

export const getItemTitleByKey = (id: number | string): string => {
  const item = Object.values(Items).find(
    (item) => item?.key?.toString() === id?.toString(),
  );
  return item ? item.title : '';
};

const DailyRewards = () => {
  const { toast } = useToast();
  const [showDailyReward, setShowDailyReward] = useState(false);
  const [hasCheckedToday, setHasCheckedToday] = useState(false);
  const [tasksStatus, setTasksStatus] = useState({} as any);
  const { data: bagList } = useBagList();
  const [reward, setReward] = useState({
    rewardList: [
      { day: 1, coins: 25000, claimed: false },
      { day: 2, coins: 35000, claimed: false },
      { day: 3, coins: 60000, claimed: false },
      { day: 4, coins: 100000, claimed: false },
      { day: 5, coins: 250000, claimed: false },
      { day: 6, coins: 500000, claimed: false },
      { day: 7, coins: 1000000, claimed: false },
    ],
    bananaId: '0',
    gameItemId: '0',
  });

  // const { data: tasksStatus, isLoading } = useQuery({
  //   queryKey: ['tasksStatus'],
  //   queryFn: fetchTasksStatus,
  // });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await fetchTasksStatus();
        setTasksStatus(data);
      } catch (error) {
        console.error('Failed to fetch tasks status:', error);
      }
    };

    fetchData();
  }, []);

  // const tasksStatus = mockdata;

  const bananaMap = useMemo(() => {
    const m = new Map();
    bagList?.data?.bananaRecordList?.forEach((banana) => {
      m.set(banana.id, banana);
    });
    return m;
  }, [bagList]);

  const { mutate: claimReward, isPending } = useMutation({
    mutationFn: () => postTaskClaim({ taskId: '1' }),
    // mutationFn: async () => {
    //   return {
    //     data: {
    //       reward: '60000',
    //       bananaId: '0',
    //       gameItemId: '0',
    //     },
    //     status: {
    //       timestamp: '1733970583757',
    //       code: '0',
    //       message: 'SUCCESS',
    //       elapsed: '40',
    //     },
    //   };
    // },

    onSuccess: (data) => {
      console.log('success data', data);
      setReward((prev) => ({
        ...prev,
        // @ts-ignore
        bananaId: data.data?.bananaId,
        // @ts-ignore
        gameItemId: data.data?.gameItemId,
      }));
    },
    onError: (error) => {
      toast({
        description: 'Oops! Something went wrong, try again later.🙏',
        duration: 3000,
      });
    },
  });
  console.log('tasksStatus', tasksStatus, hasCheckedToday);

  useEffect(() => {
    if (!hasCheckedToday && tasksStatus?.userTaskDailyDTO) {
      const { status } = tasksStatus?.userTaskDailyDTO;

      if (status === 'UNFINISH') {
        setShowDailyReward(true);
        claimReward();
      }

      setHasCheckedToday(true);
    }
  }, [tasksStatus, claimReward, hasCheckedToday]);

  // 更新签到状态
  useEffect(() => {
    if (tasksStatus?.userTaskDailyDTO) {
      const { consecutiveIndex } = tasksStatus.userTaskDailyDTO;
      setReward((prev) => ({
        ...prev,
        rewardList: prev.rewardList.map((item, index) => ({
          ...item,
          claimed: index <= Number(consecutiveIndex), // 将当前天数及之前的天数标记为已签到
        })),
      }));
    }
  }, [tasksStatus]);

  return showDailyReward ? (
    <AlertDialog defaultOpen>
      <AlertDialogContent
        enable3DBorder={false}
        showShadow={false}
        className="relatiev max-w-[380px] border-0 outline-0 bg-transparent p-0 gap-0 font-comicbd">
        <div className="absolute w-full h-[80px] top-0 left-0 z-20">
          <Image
            src={getPublicAsset('/images/task/daily-rewards-title.png')}
            alt="title background"
            className="absolute w-[101%] top-[-12px] left-0"
            height={70}
            width={400}
          />
          <div className="absolute text-center text-white text-2xl stroke-black font-bold w-full leading-normal pt-[15px]">
            Daily rewards
          </div>
        </div>
        <BaseInteractive
          onInteractiveClick={() => {
            setShowDailyReward(false);
          }}
        >
          <div className="closeButton absolute top-[-14px] right-[-17px] w-[40px] h-[40px] z-20 active:translate-y-0.5 active:translate-x-0.5">
            <Image
              fill
              src={getPublicAsset('/images/lobby/close.png')}
              alt="close"
            />
          </div>
        </BaseInteractive>
        <div className="relative w-full h-full p-0 border-0 outline-0 bg-[#FFF9E2] rounded-lg overflow-hidden">
          <div className="relative w-full h-[80px]" />
          <div className="px-6 space-y-2 mt-1">
            {/* Today's Rewards */}
            <div className="flex justify-center mb-4 gap-x-2">
              <div className="text-center">
                <div className="flex items-center justify-center">
                  <Image
                    className="inline-block align-middle mr-1"
                    width={60}
                    height={60}
                    src={getPublicAsset('/images/earn/coin.png')}
                    alt="coins"
                  />
                </div>
                <div className="text-2xl font-bold stroke-black text-white mt-[-15px]">
                  +{''}
                  <CountUp
                    preserveValue={true}
                    end={Number(
                      (() => {
                        const index = Number(
                          tasksStatus?.userTaskDailyDTO?.consecutiveIndex || 0,
                        );
                        return (
                          reward.rewardList[index]?.coins ||
                            reward.rewardList[0].coins
                        );
                      })(),
                    )}
                    redraw={false}
                  />
                </div>
                <div className="text-xl rounded-[40px] bg-white mt-1 py-1 px-2">
                  <div>Coins</div>
                </div>
              </div>
              {reward.gameItemId && reward.gameItemId !== '0' && (
                <div className="text-center">
                  <div className="flex items-center justify-center">
                    <Image
                      className="inline-block align-middle mr-1"
                      width={56}
                      height={56}
                      src={
                        ImgMap[
                        getItemTitleByKey(reward.gameItemId)?.toLowerCase()
                      ]
                      }
                      alt="coins"
                    />
                  </div>
                  <div className="text-2xl font-bold stroke-black text-white mt-[-15px]">
                    +1
                  </div>
                  <div className="text-xl rounded-[40px] bg-white py-1 px-3">
                    <div>{getItemTitleByKey(reward.gameItemId)}</div>
                  </div>
                </div>
              )}
            </div>

            {/* Calendar Grid */}
            <div className="grid grid-cols-3 gap-3">
              {reward.rewardList.slice(0, 6).map((item) => (
                <div
                  key={item.day}
                  className={`bg-[#fff] rounded-lg text-center relative pb-1 border-[3px] border-[#E6AF154D] overflow-hidden
                    ${item.claimed ? 'opacity-40' : ''}`}
                >
                  <div className="bg-[#FFDB46] border-b border-[#E6AF154D] border-3 flex items-center justify-center">
                    <div className="text-base text-[#B3671D]">Day</div>&nbsp;
                    <div className="text-lg text-[#fff] stroke-chocolate stroke-1">
                      {item.day}
                    </div>
                  </div>
                  <div className="flex items-center justify-center mt-2">
                    <Image
                      className="inline-block align-middle mr-1"
                      width={item.day === 3 ? 36 : 28}
                      height={item.day === 3 ? 36 : 28}
                      src={
                        item.day === 3
                          ? getPublicAsset('/images/task/red-pocket.png')
                          : getPublicAsset('/images/earn/coin.png')
                      }
                      alt="coins"
                    />
                  </div>
                  {item.day !== 3 && (
                    <div className="text-base text-[#fff] stroke-black mt-[-10]">
                      +{formatLargeNumber(item.coins, 1000)}
                    </div>
                  )}
                  {item.claimed && (
                    <div className="absolute bottom-1 right-1 text-green-600">
                      ✓
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Day 7 Special Reward */}
            <div
              className={`bg-[#fff] rounded-lg text-center relative pb-1 overflow-hidden border-[3px] border-[#E6AF154D] ${
                reward.rewardList[6]?.claimed ? 'opacity-40' : ''
              }`}
            >
              <div className="bg-[#FFDB46] border-b border-[#E6AF154D]">
                <span className="text-base text-[#B3671D] ">Day </span>
                <span className="text-lg text-[#fff] stroke-chocolate stroke-1">7</span>
              </div>
              <div className="flex justify-around items-center">
                <div className="flex flex-col items-center justify-center">
                  <Image
                    className="inline-block align-middle"
                    width={32}
                    height={32}
                    src={getPublicAsset('/images/earn/coin.png')}
                    alt="coins"
                  />
                  <div className="text-base text-[#fff] stroke-black mt-[-10]">
                    +{formatLargeNumber(reward.rewardList[6].coins, 1000)}
                  </div>
                </div>
                <div className="flex justify-center items-center gap-1">
                  <div className="flex items-center gap-1 flex-col">
                    <Image
                      width={48}
                      height={48}
                      src={
                        bananaMap.get(reward.bananaId)?.logo ||
                        getPublicAsset('/images/pina/PinaManiaPlaceholder.png')
                      }
                      alt="pinaapple"
                    />
                    <span className="text-xs">Paper Hands Pina</span>
                    {/* {rewards[6].special?.amount} */}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center justify-left">
                    <Image
                      alt="banana"
                      className="inline-block align-middle mr-2 h-[18px] w-[18px]"
                      width={20}
                      height={20}
                      src={getPublicAsset('/images/bag/usdt.png')}
                    />
                    <span className="text-[#fff] stroke-black text-xs">
                      0.01
                    </span>
                  </div>
                  <div className="flex items-center justify-center space-x-1 mt-2">
                    <Image
                      className="inline-block align-middle mr-1 h-[18px] w-[18px]"
                      width={20}
                      height={20}
                      src={getPublicAsset('/images/earn/coin.png')}
                      alt="banana"
                    />
                    <span className="text-[#fff] stroke-black text-xs">
                      4500
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center text-xs text-gray-500">
              Tips: Skipping a day resets your check-in.
            </div>

            <div className="flex justify-center">
              <Button
                className="w-[120px] bg-yellow-400 hover:bg-yellow-500 text-white stroke-black mt-2 mb-4"
                shadowColor="#A37F35"
                onClick={() => setShowDailyReward(false)}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  ) : null;
};

export default DailyRewards;
