'use client';

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import Image from 'next/image';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/nextjs';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogAction,
} from '@/components/ui/alert-dialog';
import { PotionItem, PotionPina, PotionDetail,
  PotionDetailResponse, fetchPotionDetail } from '@/apis/potion';
import { useBagList } from '@/apis/bag';
import { initInvoice } from '@telegram-apps/sdk';
import { fetchGetGameItem, EventId, useGameItemPackages } from '@/apis/game';
import { getPaymentLink } from '@/apis/payment';
import { ShowPromiseResult } from '@/types/adsgram';
import { useToast } from '@/components/ui/use-toast';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { cn } from '@/lib/utils';
import { clsx } from 'clsx';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import useUserStatus from '@/hooks/useUserStatus';
import { useTasksStatus } from '@/apis/task';
import { useAtom } from 'jotai';
import { isTaskCheckDialogClosedAtom } from '@/store/runtime';
import formatNumber from '@/utils/number/formatter';
import { formatMoney } from '@/utils/decimal/formatMoney';
import { LoadingIcon } from '@/components/ui/loading';
import { useRouter, usePathname } from 'next/navigation';
import { useTranslations, useLocale } from 'next-intl';
import { track } from '@/utils/thinkdata/track';

type DialogProps = {
  onClose?: Function,
};

const bananaImage = getPublicAsset('/images/lobby/pinaple-sunglasses1.png');

export const TaskCheck: React.FC<DialogProps> = ({
  onClose = () => {},
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();
  const [open, setOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [total, setTotal] = useState(0);
  const [finished, setFinished] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isTaskCheckDialogClosed, setIsTaskCheckDialogClosed] = useAtom(isTaskCheckDialogClosedAtom);
  const { data: userStatus } = useUserStatus();

  // @ts-ignore
  const { left } = useMemo(() => {
    const left = total - finished;
    return {
      left,
    }
  }, [total, finished]);

  useEffect(() => {
    setTotal(userStatus?.taskInfo?.totalCount);
    setFinished(userStatus?.taskInfo?.finishCount);
    setProgress(userStatus?.taskInfo?.percent);
    setOpen(userStatus?.taskInfo?.popWindows);
  }, [userStatus]);

  const closeHandler = () => {
    setOpen(false);
    !!onClose && onClose();
    setTimeout(() => {
      setIsTaskCheckDialogClosed(true);
    }, 0);
  };

  useEffect(() => {
    return () => {
      setIsTaskCheckDialogClosed(true);
    };
  }, []);

  return (
    <AlertDialog open={!isTaskCheckDialogClosed && open}>
      <AlertDialogContent
        className="relative flex flex-col w-10/12 p-0 bg-[#FFF4DA] font-comicbd justify-center items-center"
        shadowColor="#EE9B40"
        enable3DBorder
      >
        <BaseInteractive onInteractiveClick={closeHandler}>
          <div className="closeButton absolute w-[32px] h-[32px] right-[-16px] top-[-16px] active:translate-x-0.5 active:translate-y-0.5 z-40">
            <Image fill sizes="80vw" src={getPublicAsset('/images/lobby/close.png')} alt="close" />
          </div>
        </BaseInteractive>
        <AlertDialogTitle className="text-xl w-full bg-[#F6C334] p-4 text-center text-white stroke-black border-b-2 border-black border-black">
          Boost Your Rare Pina Rate
        </AlertDialogTitle>
        <div className="flex flex-col w-full px-4 space-y-2">
          <div className="flex flex-row justify-between items-center">
            <span>
              <small> {finished}/{total} </small> Tasks
            </span>
            <span>
              <small> {left} </small> Left
            </span>
          </div>
          <div className="flex w-full items-center justify-items-start w-[160px] h-[24px] border-solid border-2 bg-[#fff] border-[#000] rounded-2xl">
            <div className={clsx(
                'relative flex mx-[2px] items-center h-[16px] border-solid border-2 bg-[#ffe063] border-[#000] rounded-2xl',
              )}
              style={{
                width: `${progress * 100}%`
              }}
            />
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="text-base"> Curren Drop Rate: </div>
            <div className="text-2xl text-white stroke-black">
              {Math.round(progress * 100)}%
            </div>
            <div className="text-base">
              Complete
              <span className="text-white stroke-black mx-1">
                {left}
              </span>
              tasks for
              <span className="text-[#FFCB38] stroke-black mx-1">
                +{Math.round((1 - progress) * 100)}%
              </span>
              more
            </div>
          </div>
        </div>
        <AlertDialogFooter className="!flex my-2">
          <AlertDialogAction
            className="bg-[#FFCB38]"
            /* @ts-ignore */
            shadowColor="#A37F35"
            onClick={closeHandler}
          >
            Maybe Later
          </AlertDialogAction>
          <AlertDialogAction
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                router.push(`/${locale}/task`);
              }, 0);
            }}
          >
            { isLoading ? "Loading..." : "Complete Tasks" }
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
