interface CardProps {
    bgColor: string;
    shadowColor: string;
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

const Card = ({
    bgColor,
    shadowColor,
    children,
    className,
    style
}: CardProps) => {
    return (
        <div className={`relative text-black ${className}`}>
            <div className={`absolute top-2 left-2 w-full h-full ${shadowColor} border-2 border-black rounded-xl z-0`}></div>
            <div className={`w-full relative ${bgColor} border-2 border-black rounded-xl z-10`} style={style}>
                {children}
            </div>
        </div>
    );
};

export default Card; 