import React, { useCallback, useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useAtom } from 'jotai';
import { initUtils } from '@telegram-apps/sdk';
import { EventId } from '@/apis/game';
import { fetchEasterEggBonus, useEasterEggInfo } from '@/apis/easterEgg';
import { useBagList } from '@/apis/bag';
import { useToast } from '@/components/ui/use-toast';
import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { cn } from '@/lib/utils';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import { useClipboard } from '@/hooks/useClipboard';
import useBizSound from '@/hooks/useBizSound';
import SocialIcon from '@/components/ui/SocialIcon';
import { Button } from '@/components/ui/button';
import SizedConfetti from '@/components/ui/ConfettiAnimations/SizedConfetti';
import { arabicToRoman } from '@/utils/number/romanNumerals';
import CountUp from 'react-countup';
import { userEasterEggInfoAtom, userInfoAtom } from '@/store/userInfo';
import useUserStatus from '@/hooks/useUserStatus';
import {
  isEasterEggFirstTimeAtom,
  isCopiedEasterEggPassAtom,
} from '@/store/runtime';
import { useRouter, useParams, useSearchParams } from 'next/navigation';
import { getEasterEggCode } from '@/helper/getCode';
import addComma from '@/utils/number/addComma';
import {
  getEasterEggShareText,
  getTelegramMiniAppUrl,
} from '@/utils/shareLinks';
import { useLocalStorage } from '@uidotdev/usehooks';
import { LOCAL_KEY_EASTER_EGG_SHARE_COUNT } from '@/constants/localStorageKeys';
import { EasterEggContent } from './EasterEggContent';
import { EasterEggPopup } from './EasterEggPopup';
import { useTrack } from '@/hooks/useTrack';

const winBGM = getPublicAsset('/audios/win.mp3');

const card_bg = '#f6ccff';
const card_border = '#c650f6';
const card_shadow = '#7917ac';
const title_color = '#fadc60';
const text_color = '#9339bf';
const cipher_bg = '#453b4c';
const button_bg = '#cbe513';
const bottom_bg = '#fceced';

type EasterEggProps = {
  show: boolean;
  onClose: () => void;
  handleGameStart: () => void;
  originalCode?: string;
};

export const EasterEgg: React.FC<EasterEggProps> = ({
  show,
  onClose,
  originalCode,
  handleGameStart,
}) => {
  const { track } = useTrack();
  const searchParams = useSearchParams();
  const [_show, setSelfShow] = useState(false);
  const [ignoreSearchParams, setIgnoreSearchParams] = useState(false);
  const [inputType, setInputType] = useState('text');
  const [code, setCode] = useState(originalCode);
  const [bouns, setBouns] = useState(null);
  // const [bouns, setBouns] = useState({ bananaId: 13, gold: 100000, banana: { theme: 'wokong', level: 13, sellAmount: 1, sellGold: 1000 }});
  const [err, setErr] = useState(null);
  const { data: userStats, refetchUserStatus } = useUserStatus();
  const { userInfo } = userStats;
  const [, setEasterEggInfo] = useAtom(userEasterEggInfoAtom);
  const { data: easterEggInfo, refetch: refetchEasterEggInfo } =
    useEasterEggInfo();
  const [status, setStatus] = useState('');
  const [eggBonusStatus, setEggBonusStatus] = useState('');
  const [showReward, setShowReward] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [playWinBgm] = useBizSound(winBGM);
  const { lng } = useParams();
  const router = useRouter();

  const _close = () => {
    if (getEasterEggCode(searchParams)) {
      setIgnoreSearchParams(true);
      router.replace('/');
    }
    setSelfShow(false);
    setEggBonusStatus('');
    onClose();
  };

  useEffect(() => {
    if (userInfo?.claimEasterEgg) {
      return setStatus('own_claimed');
    }
    return setStatus('noclaim');
  }, [show, userInfo, easterEggInfo, searchParams]);

  useEffect(() => {
    if (!ignoreSearchParams && getEasterEggCode(searchParams)) {
      setInputType('text');
      setCode(`${getEasterEggCode(searchParams)}`);
      setSelfShow(true);
    } else {
      // setInputType('number');
      setCode('');
    }
  }, [searchParams, ignoreSearchParams]);

  useEffect(() => {
    if (!easterEggInfo) return;
    setEasterEggInfo(easterEggInfo);
  }, [easterEggInfo]);

  const { mutate: getEasterEggBonus, isPending: isClaiming } = useMutation({
    mutationFn: (code: string) => {
      return fetchEasterEggBonus(code);
    },
    onSuccess: async ({ data }: any) => {
      await Promise.all([refetchEasterEggInfo(), refetchUserStatus()]);
      setIgnoreSearchParams(true);
      setStatus('own_claimed');

      playWinBgm();
      setEggBonusStatus('success');
    },
    onError: ({ data }: any) => {
      if (data?.status?.code === '10064') {
        // 10064 表示用户已经领取过奖励
        // setErr(data?.status?.message);
        setStatus('already_claimed');
      }
      if (data?.status?.code === '10065') {
        // 10065 表示用户密码错误
        // setEggBonusStatus('failed');
        setStatus('failed_claimed');
      }
    },
  });

  const handleClaim = useCallback(() => {
    if (!code) return;
    track('easterEgg_claim', { code: code });
    getEasterEggBonus(code);
  }, [code]);

  const handleViewReward = useCallback(() => {
    setIsRedirecting(true);
    track('easterEgg_reward', { reward: bouns });
    router.push(`/${lng}/shareReward`);
  }, [lng]);

  const handlePlayGame = useCallback(() => {
    _close();
    track('easterEgg_playgame', { from: 'easter_egg' });
    handleGameStart();
  }, [lng, handleGameStart]);

  return (
    <>
      <EasterEggPopup
        handleViewReward={handleViewReward}
        isRedirecting={isRedirecting}
      />
      <AlertDialog open={show || _show}>
        <AlertDialogContent
          className={'relative flex rounded-[16px] w-10/12 px-0 py-0'}
          enable3DBorder={false}
        >
          <div className="relative bg-[#c650f6] rounded-[16px] w-full h-full p-2 border-[2px] border-black shadow-[6px_6px_0px_#7917ac]">
            <div className="relative bg-[#f6ccff] rounded-[16px] w-full h-full border-[2px] border-black">
              <BaseInteractive onInteractiveClick={_close}>
                <div className="closeButton absolute top-[-18px] right-[-18px] w-[40px] h-[40px] z-20 active:translate-y-0.5 active:translate-x-0.5">
                  <Image
                    fill
                    src={getPublicAsset('/images/lobby/close.png')}
                    alt="close"
                  />
                </div>
              </BaseInteractive>
              <AlertDialogTitle className="absolute top-[0] rounded-t-[16px] text-center font-comicbd w-[120%] left-[-10%] h-[45px] leading-10">
                <div className="relative">
                  <Image
                    className="absolute w-full h-auto translate-y-[-80%]"
                    src={getPublicAsset('/images/easter-egg/head.png')}
                    width={662}
                    height={398}
                    alt="easter egg header"
                  />
                  <span className="absolute w-full h-auto left-0 bottom-[-5px] uppercase text-center text-[#fadc60] text-[2rem] stroke-black">
                    {' '}
                    Easter Egg{' '}
                  </span>
                  <Image
                    className="absolute w-[21px] h-[25px] left-[24px] bottom-[64px]"
                    src={getPublicAsset('/images/easter-egg/star.png')}
                    width={42}
                    height={50}
                    alt="easter star"
                  />
                  <Image
                    className="absolute w-[21px] h-[25px] left-[12px] bottom-[-64px]"
                    src={getPublicAsset('/images/easter-egg/star.png')}
                    width={42}
                    height={50}
                    alt="easter star"
                  />
                  <Image
                    className="absolute w-[21px] h-[25px] right-[12px] bottom-[-364px]"
                    src={getPublicAsset('/images/easter-egg/star.png')}
                    width={42}
                    height={50}
                    alt="easter star"
                  />
                </div>
              </AlertDialogTitle>
              <EasterEggContent
                easterEggInfo={easterEggInfo}
                status={status}
                type={inputType}
                code={code}
                bouns={bouns}
                err={err}
                eggBonusStatus={eggBonusStatus}
                isClaiming={isClaiming}
                isRedirecting={isRedirecting}
                handleCodeChange={(value) => {
                  setCode(value);
                }}
                handleClaim={handleClaim}
                handleViewReward={handleViewReward}
                handlePlayGame={handlePlayGame}
              />
              <AlertDialogFooter />
            </div>
          </div>
          <div
            className={`absolute w-full h-full left-0 top-0 inset-0 pointer-events-none ${eggBonusStatus === 'success' ? 'z-[1000] block' : '-z-20 hidden'}`}
          >
            <SizedConfetti
              style={{ pointerEvents: 'none', width: '100%' }}
              numberOfPieces={eggBonusStatus === 'success' ? 500 : 0}
              recycle={false}
              initialVelocityX={0}
              initialVelocityY={0}
              gravity={0.07}
              tweenDuration={7500}
            />
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
