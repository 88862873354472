'use client';

import { store } from '@/store';
import { useEffect, useState, useMemo, useContext, createContext } from 'react';
import { Provider, useAtom } from 'jotai';
import { queryClient } from './queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { usePathname, useSearchParams } from 'next/navigation';
import { tokenManager } from '@/store/userInfo';
import ClientOnly from '@/components/ui/ClientOnly';
import { getLocalStorage } from '@/utils/localStoreHelper';
import TelegramProvider from '@/components/telegram/TelegramProvider';
import InjectedTelegramEnv from '@/components/telegram/mockTelegramMiniAllEnv';
import { initializeAnalytics } from '@/helper/analytics';
import { isProduction } from '@/utils/checkRuntime';
import thinkingdata from 'thinkingdata-browser';
// import dynamic from 'next/dynamic'

// const TwaAnalyticsProvider = dynamic(
//   () => import('@tonsolutions/telemetree-react').then((mod) => mod.TwaAnalyticsProvider),
//   { ssr: false }
// );

export interface ITelegramContext {
  webApp?: any;
  user?: {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    language_code: string;
  };
}

const TAconfig = {
  appId: 'debug-appid', // 配置
  name: 'Ta',
  serverUrl: 'https://te.pinamania.com',
};

export const TelegramContext = createContext<ITelegramContext>({});

export const AppClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const searchParams = useSearchParams();
  const [token, setToken] = useAtom(tokenManager, { store: store });

  const [webApp, setWebApp] = useState<null>(null);

  useEffect(() => {
    if (!token) {
      setToken(searchParams.get('token') || getLocalStorage('token'));
    }
    InjectedTelegramEnv();
    isProduction && initializeAnalytics();
  }, []);

  useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    if (app) {
      app.ready();
      app.expand();
      setWebApp(app);
    }
  }, []);

  useEffect(() => {
    // AffiseMMP init
    window?.AffiseMMP &&
      window?.AffiseMMP.init({
      affise_app_token: '5IZ19TAF63K9B',
    });
    // thinkdata init
    thinkingdata.init(TAconfig);
  }, []);

  useEffect(() => {
    // AffiseMMP init
    window?.AffiseMMP &&
      window?.AffiseMMP.registerEvents([
        {
          affise_event_name: 'tma_first_launch',
          affise_parameters: {
            affise_p_utm_source: 'UTM_SOURCE',
            affise_p_utm_medium: 'UTM_MEDIUM',
            affise_p_utm_campaign: 'UTM_CAMPAIGN',
            affise_p_content_type: 'CONTENT_TYPE',
            affise_p_param_01:
              (window as any).Telegram?.WebApp?.initDataUnsafe?.user
                ?.username || 'Unknown username',
            affise_p_param_02:
              (window as any).Telegram?.WebApp?.initDataUnsafe?.user
                ?.first_name || 'Unknown first_name',
            affise_p_param_03:
              (window as any).Telegram?.WebApp?.initDataUnsafe?.user
                ?.last_name || 'Unknown last_name',
            affise_p_param_04:
              (window as any).Telegram?.WebApp?.initDataUnsafe?.user
                ?.language_code || 'Unknown language_code',
          },
        },
      ]);
  }, []);

  const value = useMemo(() => {
    return webApp
      ? {
          webApp,
          // @ts-ignore
          unsafeData: webApp.initDataUnsafe,
          // @ts-ignore
          user: webApp.initDataUnsafe.user,
        }
      : {};
  }, [webApp]);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ClientOnly>
          <TelegramProvider value={value}>
            {children}
          </TelegramProvider>
        </ClientOnly>
      </QueryClientProvider>
    </Provider>
  );
};

export const useTelegram = () => useContext(TelegramContext);
