import { useCallback, useEffect, useRef, useState } from 'react';
import { AdController, ShowPromiseResult } from '@/types/adsgram';
import { isProduction } from '@/utils/checkRuntime';
import { TonAdPopupShow, TonAdInit } from 'ton-ai-sdk';
import { initUtils, retrieveLaunchParams } from "@telegram-apps/sdk";
import { isAdPlayingAtom } from '@/store/runtime';
import { useAtom } from 'jotai';
import * as Sentry from '@sentry/nextjs';
import { useTrack } from '@/hooks/useTrack';

export interface useAdsgramParams {
  blockId?: string;
  onReward: () => void;
  onError?: (result: ShowPromiseResult) => void;
  trackData?: {
    scene: string;
    entrance: string;
  };
}

export const useAdsgram = ({
  blockId = process.env.NEXT_PUBLIC_AD_BLOCKID,
  onReward,
  onError,
  trackData,
}: useAdsgramParams) => {
  const { track } = useTrack();
  const AdControllerRef = useRef<AdController | undefined>(undefined);
  const [lastAdPlaytime, setLastAdPlaytime] = useState(0);
  const [, setIsAdPlaying] = useAtom(isAdPlayingAtom);

  useEffect(() => {
    AdControllerRef.current = window?.Adsgram?.init({
      blockId,
      debug: !isProduction,
      debugBannerType: 'FullscreenMedia',
    });

    AdControllerRef.current?.addEventListener('onStart', () => {
      track('ad_show', {
        adScene: trackData?.scene,
        entrance: trackData?.entrance,
      });
      setIsAdPlaying(true);
    });

    AdControllerRef.current?.addEventListener('onReward', () => {
      setIsAdPlaying(false);
    });

    AdControllerRef.current?.addEventListener('onError', () => {
      setIsAdPlaying(false);
    });
  }, [blockId]);

  const showAdsgramAd = useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        .show()
        .then(() => {
          // user skip ad
          // onSkip()
          // user watch ad till the end
          track('ad_close', {
            adScene: trackData?.scene,
            entrance: trackData?.entrance,
          });
          onReward();
          setLastAdPlaytime(Date.now());
        })
        .catch((result: ShowPromiseResult) => {
          track('ad_fail', {
            adScene: trackData?.scene,
            entrance: trackData?.entrance,
          });
          // user get error during playing ad or skip ad
          onError?.(result);
          Sentry.captureMessage('Adsgram error', {
            extra: {
              error: JSON.stringify(result),
            },
          });
        });
    } else {
      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
      });
    }
  }, [onError, onReward]);

  return { showAdsgramAd, lastAdPlaytime };
}

export interface useOpenAdsParams {
  zoneId: number;
  publisherId: number;
  onReward: () => void;
  onError?: (result: ShowPromiseResult) => void;
  trackData?: {
    scene: string;
    entrance: string;
  };
}

export const useOpenADInteractive = ({ zoneId, publisherId, onReward, onError, trackData }:useOpenAdsParams) => {
  const { track } = useTrack();
  const [openADIPlayable, setOpenADIPlayable] = useState(false);
  const [, setIsAdPlaying] = useAtom(isAdPlayingAtom);

  const openADInteractive = {
    adInfo: {
      zoneId, // int,  This is an example zoneId, please get your own code parameters
      publisherId, // int, This is an example publisherId, please get your own code parameters
    },
    userInfo: {
      userId: '', // user ID , If there is no data, please leave it blank
      firstName: '', // firstName or userId , If there is no data, please leave it blank
      lastName: '', // lastName or userId , If there is no data, please leave it blank
      username: '', // username or userId , If there is no data, please leave it blank
    },
    adParams: {
      version: '', //your app version , If there is no data, please leave it blank
      TG: { type: '@telegram-apps/sdk', FN: { initUtils, retrieveLaunchParams } },
      /**If you've loaded `https://telegram.org/js/telegram-web-app.js` in your TMA**/
      //TG = { type: 'telegram', FN: window.Telegram };
      /**If you're using `@telegram-apps/sdk` in your TMA**/
      //import { initUtils, retrieveLaunchParams } from "@telegram-apps/sdk";
      //TG: { type: '@telegram-apps/sdk', FN: { initUtils, retrieveLaunchParams } };
      /**If you haven't loaded any Telegram SDK**/
      //TG = { type: 'noSDK', FN: null }
    },
    hasAD: false, //
  };

  useEffect(() => {
    window?.openADJsSDK?.interactive.init(openADInteractive).then(res => {
      console.log('openAD', res);
      setOpenADIPlayable(res.code === 0);
    })
  }, []);

  const cb = {
    // indicates load ad resource from OpenAD platform, false will be returned if there is no resource to be loaded for the publisher slot/zone
    adResourceLoad: (e) => {
      console.log('step1 adResourceLoad', e);  // e = ture / false
    },
    // indicates the interactive ad is opening
    adOpening: (e) => {
      console.log('step2 adOpening', e); // e = ture / false
    },
    // indicates the interactive ad is opened,
    adOpened: (e) => {
      setIsAdPlaying(true);
      track('ad_show', {
        adScene: trackData?.scene,
        entrance: trackData?.entrance,
      });
      console.log('step3 adOpened', e);  // e = ture / false
    },
    // indicates the interactive ad task is finished, the task is defined by advertiser
    adTaskFinished: (e) => {
      console.log('step5 adTaskFinished', e);  // e = viewAD / click
    },
    // indicates the interactive ad is closing
    adClosing: (e) => {
      console.log('step6 adClosing', e);  // e = viewAD / click
    },
    // indicates the interactive ad is closed
    adClosed: (e) => {
      console.log('step7 adClosed', e);   // e = viewAD / click, if you want to do something through different types, you can write here.
      setIsAdPlaying(false);
      onReward();
      track('ad_close', {
        adScene: trackData?.scene,
        entrance: trackData?.entrance,
      });
    },
    // indicates clicked and jumps
    adClick: (e) => {
      console.log('step4 adClick', e);  // e = ture / false
    },
  };

  const Render = () => {
    try {
      window?.openADJsSDK?.interactive.getRender({ adInfo: openADInteractive.adInfo, cb });
    } catch (err) {
      console.error('Ad error:', err);
      track('ad_fail', {
        adScene: trackData?.scene,
        entrance: trackData?.entrance,
      });
      Sentry.captureMessage('openAD error', {
        extra: {
          error: JSON.stringify(err),
        },
      });
      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'openAD run error',
      });
    }
  };

  return { openADIPlayable, Render };
};

export interface useTonAIAdParams {
  appId?: string;
  blockId?: string;
  onReward: () => void;
  onError?: (result: ShowPromiseResult) => void;
  trackData?: {
    scene: string;
    entrance: string;
  };
}

export const useTonAIAd = ({
  appId = process.env.NEXT_PUBLIC_TONAIAD_APPID,
  blockId = process.env.NEXT_PUBLIC_TONAIAD_BLOCKID,
  onReward,
  onError,
  trackData,
}: useTonAIAdParams) => {
  const { track } = useTrack();
  const [, setIsAdPlaying] = useAtom(isAdPlayingAtom);
  TonAdInit({ appId, debug: !isProduction });

  const showTonAIAd = useCallback(() => {
    setIsAdPlaying(true);
    TonAdPopupShow({
      blockId,
      countdown: 10,  // 可以不设置，默认值为 10 秒，最小值为 10 秒，最大值为 30 秒
      autoClose: true, // 默认值为 true，表示广告倒计时结束后自动关闭广告
      onAdClick: (ad) => {
      },
      onAdComplete: (ad) => {
        setIsAdPlaying(false);
        onReward();
        track('ad_close', {
          adScene: trackData?.scene,
          entrance: trackData?.entrance,
        });
      },
      onAdError: (error) => {
        console.error('Ad error:', error);
        setIsAdPlaying(false);
        track('ad_fail', {
          adScene: trackData?.scene,
          entrance: trackData?.entrance,
        });
        Sentry.captureMessage('tonAIAd error', {
          extra: {
            error: JSON.stringify(error),
          },
        });
        onError?.({
          error: true,
          done: false,
          state: 'load',
          description: 'tonAIAd run error',
        });
      },
    })

  }, []);

  return { showTonAIAd };
};
