import React, { useEffect, useState, useCallback } from 'react';

import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { fetchGetAdsCount } from '@/apis/game';
import { useAdsgram, useOpenADInteractive, useTonAIAd } from '@/hooks/useAds';
import { ShowPromiseResult } from '@/types/adsgram';
import { Button } from '@/components/ui/button';
import { ShareButton } from '../ShareButton';
import { telegramShare, getShareLink, SHARE_TEXT } from '@/utils/shareLinks';
import { userInfoAtom } from '@/store/userInfo';
import { initUtils } from '@telegram-apps/sdk-react';
import { useStore } from 'jotai';
import { cn } from '@/lib/utils';
import { useTrack } from '@/hooks/useTrack';
import { useMonthlyPass } from '@/hooks/useMonthlyPass';
type AdsButtonProps = {
  text: string;
  onAdsReward: () => void;
  onAdsError: (result: ShowPromiseResult) => void;
  className?: string;
  shadowColor?: string;
  onShareSuccess?: () => void;
  trackData?: {
    scene: string;
    entrance: string;
  };
};

export const AdsButton: React.FC<AdsButtonProps> = ({
  text,
  onAdsReward,
  onAdsError,
  className,
  shadowColor,
  onShareSuccess,
  trackData,
}) => {
  const utils = initUtils();
  const store = useStore();
  const userInfo = store.get(userInfoAtom);
  const { track } = useTrack();
  const [adsData, setAdsData] = useState({} as any);
  const [err, setErr] = useState(false);

  const handleShare = useCallback(() => {
    utils.openTelegramLink(
      telegramShare({
        link: getShareLink('pina', userInfo.tgId),
        shareText: SHARE_TEXT,
      }),
    );
    track('share_trigger', {
      shareScene: trackData?.scene,
      entrance: trackData?.entrance,
    });
    onShareSuccess && onShareSuccess();
  }, [onShareSuccess]);

  const handleError = (result: ShowPromiseResult) => {
    // setErr(true);
    // onAdsError(result);
    // give user reward to continue the game
    // onAdsReward();

    // if no ad error, share to continue
    handleShare();
  };
  const { hasMonthlyPassFreeAds } = useMonthlyPass();
  const { showAdsgramAd, lastAdPlaytime } = useAdsgram({
    onReward: onAdsReward,
    onError: handleError,
    trackData,
  });

  const { Render: showOpenAD, openADIPlayable } = useOpenADInteractive({
    zoneId: Number(process.env.NEXT_PUBLIC_TON_OPENAD_INTERACTIVE_ZONEID),
    publisherId: Number(process.env.NEXT_PUBLIC_TON_OPENAD_INTERACTIVE_PUBLISHERID),
    onReward: onAdsReward,
    onError: handleError,
    trackData,
  });

  const { showTonAIAd } = useTonAIAd({
    onReward: onAdsReward,
    onError: handleError,
    trackData,
  });

  // const getAdsCounts = async () => {
  //   const resp = await fetchGetAdsCount();
  //   setAdsData(resp);
  // };

  // useEffect(() => {
  //   getAdsCounts();
  // }, []);

  const handleWatchAds = useCallback(() => {
    if (hasMonthlyPassFreeAds) {
      onAdsReward();
      return;
    }
    track('ad_trigger', {
      adScene: trackData?.scene,
      entrance: trackData?.entrance,
    });

    const adsList = [
      showTonAIAd,
    ];

    if (Date.now() - lastAdPlaytime > 30 * 1000) {
      adsList.push(showAdsgramAd);
    }

    if (openADIPlayable) {
      adsList.push(showOpenAD);
    }

    const randomIndex = Math.floor(Math.random() * adsList.length);
    const showAd = adsList[randomIndex];

    if (showAd) {
      return showAd();
    }

    // if no ad avaiable, just give reward to user
    // onAdsReward();

    // if no ad avaiable, share to continue
    handleShare();
  }, [lastAdPlaytime, openADIPlayable]);

  // 广告次数耗尽
  // if (!adsData?.allow || err) {
  //   return (
  //     <ShareButton
  //       text={text}
  //       onShareSuccess={onShareSuccess}
  //       className={className}
  //       trackData={trackData}
  //     />
  //   );
  // }

  return (
    <Button
      onClick={handleWatchAds}
      className={cn(
        'flex flex-col !leading-[0.2] justify-center items-center px-[20px] flex-shrink-0 bg-[#F6C334]',
        className,
      )}
      shadowColor={shadowColor || '#F6C334'}
    >
      <div className="flex space-y-1 justify-center items-center">
        <Image
          width={30}
          height={30}
          src={getPublicAsset('/images/game/ads.png')}
          alt=""
        />
        {/*<span className="text-xs">*/}
        {/*  ({adsData?.todayWatchAdTimes}/{adsData?.maxWatchAdTimes})*/}
        {/*</span>*/}
        <div className="text-nowrap text-lg text-white stroke-1 stroke-black">{text}</div>
      </div>

      {hasMonthlyPassFreeAds && <div className='text-sm text-red-500'>Ad-free for Members</div>}

    </Button>
  );
};
